import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { fetchImageByUid } from '../Actions/Main-Actions';
import { message } from 'antd';
import WelfareCard from './WelfareCard';

export const FullWelfareCard = (props) => {
    const data = props.data;
    const [imageData, setImageData] = useState();
    const fetchImage = async () => {
        try {
            const imgBlob = await fetchImageByUid(data.id);
            setImageData(imgBlob);
        } catch (error) {
            message.error(error);
        }
    }
    useEffect(() => {
        setImageData(undefined)
        fetchImage();
    }, [props])


    return (
        <>
            {(!data || !imageData) && <div className='align-center'><LoadingOutlined /></div>}
            {data && imageData && <div className='align-center'>
                <WelfareCard data={data} pic={imageData} />
            </div>}
        </>
    )
}
