import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import DistrictView from './DistrictView';
const OldMembers = () => {
    const membersStyle = ['core-members','voting-members','president-candidates','secretary-candidates','joint-secretary-candidates']
    const data = {
        "core-members": [
            {
                title: "రాష్ట్ర ప్రెసిడెంట్",
                name: "కొండవీటి శ్రీహరి గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-23.jpeg",
            },
            {
                title: "రాష్ట్ర సెక్రటరీ",
                name: "సత్తార్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-25.jpeg",
            },
            {
                title: "రాష్ట్ర వైస్ ప్రెసిడెంట్",
                name: "సత్యనారాయణ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-21.jpeg",
            },
            {
                title: "రాష్ట్ర జాయింట్ సెక్రటరీ",
                name: "హరికిషోర్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-20.jpeg",
            },
            {
                title: "రాష్ట్ర ట్రెజరీ",
                name: "కె రామక్రిష్ణ ఆళ్ళగడ్డ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-22.jpeg",
            },
        ],
        "ec-members": [
            {
                title: "కర్నూలు జిల్లా రాష్ట్రఇసి",
                name: "మధుసూదన్ రెడ్డి గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-1.jpeg",
            },
            {
                title: "శ్రీకాకుళం జిల్లా రాష్ట్రఇసి",
                name: "ఉమాశంకర్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-18.jpeg",
            },
            {
                title: "కడప జిల్లా రాష్ట్ర రాష్ట్రఇసి",
                name: "సలీం గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-26.jpeg",
            },
            {
                title: "పశ్చిమ గోదావరి జిల్లా రాష్ట్రఇసి",
                name: "మల్లి కార్జున గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-2.jpeg",
            },
            {
                title: "గుంటూరు జిల్లా రాష్ట్రఇసి",
                name: "శ్రీనివాస్ రెడ్డి గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-16.jpeg",
            },
            {
                title: "తూర్పు గోదావరి జిల్లా రాష్ట్రఇసి",
                name: "వాసు గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-3.jpeg",
            },

            {
                title: "తూర్పు గోదావరి జిల్లా రాష్ట్రఇసి ",
                name: "జి వి కె ప్రసాద్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-4.jpeg",
            },
            {
                title: "క్రిష్ణా జిల్లా రాష్ట్రఇసి",
                name: "యం, పాషా గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-17.jpeg",
            },
            {
                title: "క్రిష్ణా జిల్లా రాష్ట్రఇసి",
                name: "డి ఉమ రావు గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-28.jpeg",
            },
            {
                title: "క్రిష్ణా జిల్లా రాష్ట్రఇసి",
                name: "తిరువూరు శేఖర్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-24.jpeg",
            },
            {
                title: "అనంతపురం జిల్లా రాష్ట్రఇసి",
                name: "సాయిబాబు గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-5.jpeg",
            },

            {
                title: "అనంతపురం జిల్లా రాష్ట్రఇసి",
                name: "BST నాగరాజు గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-6.jpeg",
            },

            {
                title: "ప్రకాశం జిల్లా రాష్ట్రఇసి",
                name: "కోకిల గడ్డ విజయ్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-7.jpeg",
            },

            {
                title: "ప్రకాశం జిల్లా రాష్ట్రఇసి",
                name: "బాషా గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-8.jpeg",
            },

            {
                title: "విశాఖ జిల్లా రాష్ట్రఇసి",
                name: "త్రీనాధ్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-9.jpeg",
            },

            {
                title: "విశాఖ జిల్లా రాష్ట్ర ఇసి",
                name: "మాధవ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-10.jpeg",
            },

            {
                title: "విజయనగరం జిల్లా రాష్ట్రఇసి",
                name: "బొబ్బిలి బాలాజీ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-11.jpeg",
            },

            {
                title: "విజయనగరం జిల్లా రాష్ట్రఇసి",
                name: "రౌతు శ్రీనివాస్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-12.jpeg",
            },

            {
                title: "చిత్తూరు జిల్లా రాష్ట్రఇసి",
                name: "ముత్తు గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-13.jpeg",
            },

            {
                title: "చిత్తూరు జిల్లా రాష్ట్ర ఇసి",
                name: "కిషోర్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-14.jpeg",
            },
            {
                title: "నెల్లూరు జిల్లా రాష్ట్ర ఇసి",
                name: "కె మును స్వామి గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-27.jpeg",
            },
            {
                title: "నెల్లూరు జిల్లా రాష్ట్ర ఇసి",
                name: "దుడ్డు రామక్రిష్ణ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-19.jpeg",
            },
        ],
        "district-members": [
            {
                title: "శ్రీకాకుళం జిల్లా",
                name: "డి రవి కుమార్ గారు",
                src: "assets/img/presidents/president-1.jpeg",
            },
            {
                title: "అనంతపురం జిల్లా",
                name: "ఎస్ నాగరాజు గారు",
                src: "assets/img/presidents/president-2.jpeg",
            },
            {
                title: "విశాఖ జిల్లా",
                name: "ఫరూక్ అలీ గారు",
                src: "assets/img/presidents/president-3.jpeg",
            },
            {
                title: "క్రిష్ణా జిల్లా",
                name: "విజేత రాము గారు",
                src: "assets/img/presidents/president-4.jpeg",
            },
            {
                title: "గుంటూరు జిల్లా",
                name: "సుబ్బారావు గారు",
                src: "assets/img/presidents/president-5.jpeg",
            },
            {
                title: "పశ్చిమ గోదావరి జిల్లా",
                name: "సత్యనారాయణ గారు",
                src: "assets/img/presidents/president-6.jpeg",
            },

            {
                title: "ప్రకాశం జిల్లా",
                name: "ఉశేన్ బాషా గారు",
                src: "assets/img/presidents/president-7.jpeg",
            },
            {
                title: "కర్నూలు జిల్లా",
                name: "ఆర్ కె రెడ్డి గారు",
                src: "assets/img/presidents/president-8.jpeg",
            },
            {
                title: "విజయనగరం జిల్లా",
                name: "రౌతు శ్రీనివాస్ గారు",
                src: "assets/img/presidents/president-9.jpeg",
            },
            {
                title: "నెల్లూరు జిల్లా",
                name: "రియాజ్ గారు",
                src: "assets/img/presidents/president-10.jpeg",
            },

            {
                title: "కడప జిల్లా",
                name: "లక్ష్మి రెడ్డి గారు",
                src: "assets/img/presidents/president-11.jpeg",
            },

            {
                title: "చిత్తూరు జిల్లా",
                name: "లోకేష్ గారు",
                src: "assets/img/presidents/president-12.jpeg",
            },

            {
                title: "తూర్పు గోదావరి జిల్లా",
                name: "నటరాజు గారు",
                src: "assets/img/presidents/president-13.jpeg",
            },
        ],
        gallery: [
            {
                src: 1,
                end: 0,
                title: "First Meet",
                location: "Vijayawada",
            },
            {
                src: 2,
                end: 14,
                title: "Second Meet",
                location: "Kurnool",
            },
            {
                src: 16,
                end: 17,
                title: "Third Meet",
                location: "Vijayawada",
            },
        ],
    };

    const getDistrictMembers = (data, name, viewType) => {
        const updatedData = []
        for (let i = 0; i < data[name].length;) {
            const colData = []
            for (let x = 0; x < 6 && i < data[name].length; x++) {
                colData.push(data[name][i])
                i++;
            }
            updatedData.push(colData);
        }
        return <>
            {
                updatedData.map((val, i) => {
                    return (<div className="row">
                        {
                            val.map((col) => {
                               return membersStyle.includes(name) ? getVoterListSingle(col, viewType) : getDistrictSingle(col, viewType)
                            })
                        }
                    </div>
                    )
                })
            }
        </>

    }
    const getVoterListSingle = (data, viewType) => {
        return <>
            <div className="col-md-3">
                <div className={viewType}>
                    <div className="pic">
                        <img src={data["src"]} alt="" /></div>
                    <h4>
                        {data["name"]}
                    </h4>
                    <span>
                        {data["title"]}
                    </span>
                </div>
            </div>
        </>
    }
    const getDistrictSingle = (data, viewType) => {
        return <>
            <div className="col-md-2">
                <div className={viewType}>
                    <div className="pic">
                        <img src={data["src"]} alt="" /></div>
                    <span style={{fontWeight:700, fontSize: "20px"}}>
                        {data["name"]}
                    </span>
                    <span>
                        {data["title"]}
                    </span>
                </div>
            </div>
        </>
    }
    useEffect(() => {
        init()
    }, [])
    const init = () => {

        /**
         * Easy selector helper function
         */
        const select = (el, all = false) => {
            el = el.trim();
            if (all) {
                return [...document.querySelectorAll(el)];
            } else {
                return document.querySelector(el);
            }
        };

        /**
         * Easy event listener function
         */
        const on = (type, el, listener, all = false) => {
            let selectEl = select(el, all);
            if (selectEl) {
                if (all) {
                    selectEl.forEach((e) => e.addEventListener(type, listener));
                } else {
                    selectEl.addEventListener(type, listener);
                }
            }
        };

        /**
         * Easy on scroll event listener
         */
        const onscroll = (el, listener) => {
            el.addEventListener("scroll", listener);
        };

        /**
         * Navbar links active state on scroll
         */
        let navbarlinks = select("#navbar .scrollto", true);
        const navbarlinksActive = () => {
            let position = window.scrollY + 300;
            navbarlinks.forEach((navbarlink) => {
                if (!navbarlink.hash) return;
                let section = select(navbarlink.hash);
                if (!section) return;
                if (
                    position >= section.offsetTop &&
                    position <= section.offsetTop + section.offsetHeight
                ) {
                    navbarlink.classList.add("active");
                } else {
                    navbarlink.classList.remove("active");
                }
            });
        };
        window.addEventListener("load", navbarlinksActive);
        onscroll(document, navbarlinksActive);

        /**
         * Scrolls to an element with header offset
         */
        const scrollto = (el) => {
            let header = select("#header");
            let offset = header.offsetHeight;

            let elementPos = select(el).offsetTop;
            window.scrollTo({
                top: elementPos - offset,
                behavior: "smooth",
            });
        };

        /**
         * Header fixed top on scroll
         */
        let selectHeader = select("#header");
        if (selectHeader) {
            let headerOffset = selectHeader.offsetTop;
            let nextElement = selectHeader.nextElementSibling;
            const headerFixed = () => {
                if (headerOffset - window.scrollY <= 0) {
                    selectHeader.classList.add("fixed-top");
                    nextElement.classList.add("scrolled-offset");
                } else {
                    selectHeader.classList.remove("fixed-top");
                    nextElement.classList.remove("scrolled-offset");
                }
            };
            window.addEventListener("load", headerFixed);
            onscroll(document, headerFixed);
        }

        /**
         * Back to top button
         */
        let backtotop = select(".back-to-top");
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add("active");
                } else {
                    backtotop.classList.remove("active");
                }
            };
            window.addEventListener("load", toggleBacktotop);
            onscroll(document, toggleBacktotop);
        }

        /**
         * Mobile nav toggle
         */
        on("click", ".mobile-nav-toggle", function (e) {
            select("#navbar").classList.toggle("navbar-mobile");
            this.classList.toggle("bi-list");
            this.classList.toggle("bi-x");
        });

        /**
         * Mobile nav dropdowns activate
         */
        on(
            "click",
            ".navbar .dropdown > a",
            function (e) {
                if (select("#navbar").classList.contains("navbar-mobile")) {
                    e.preventDefault();
                    this.nextElementSibling.classList.toggle("dropdown-active");
                }
            },
            true
        );

    }



    return (
        <>
            <main id="main">
                <section id="state-committee">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">State Committee</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="state-comity">
                            {
                                getDistrictMembers(data, "core-members", "profile")
                            }
                        </div>
                    </div>
                </section>

                <section id="team">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Executive Committee</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="team-members">
                            {getDistrictMembers(data, "ec-members", "member")}
                        </div>
                    </div>

                </section>

                <section id="district-presidents">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">District Presidents</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="district-president-members">
                            {getDistrictMembers(data, "district-members", "member")}

                        </div>
                    </div>

                </section>
                
                {/* <section id="president-candidates">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">President Candidates</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="president-candidates">
                            {getDistrictMembers(voterlist, "president-candidates", "member")} 

                        </div>
                    </div>
                </section>

                <section id="secretary-candidates">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Secretary Candidates</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="secretary-candidates">
                            {getDistrictMembers(voterlist, "secretary-candidates", "member")} 

                        </div>
                    </div>
                </section>

                <section id="joint-secretary-candidates">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Joint Secretary Candidates</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="joint-secretary-candidates">
                            {getDistrictMembers(voterlist, "joint-secretary-candidates", "member")} 

                        </div>
                    </div>
                </section>

                <section id="voting-members">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Voting Members</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="voting-members">
                            {getDistrictMembers(voterlist, "voting-members", "member")}

                        </div>
                    </div>
                </section>  */}

            </main>

            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright">
                                &copy; Copyright <strong>Imperial Theme</strong>. All Rights Reserved
                            </div>
                            <div className="credits">
                                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>. Integrated by Siva.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* <div id="preloader"></div> */}
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

            {/* <script src="assets/vendor/aos/aos.js"></script>
            <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
            <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
            <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
            <script src="assets/vendor/php-email-form/validate.js"></script>
            <script src="assets/vendor/swiper/swiper-bundle.min.js"></script> */}

            <script src="assets/js/main.js"></script>
        </>
    )
}

export default OldMembers