import React, { useState } from 'react';
import ImgCrop from 'antd-img-crop';
import {
    Form,
    Input,
    Button,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    Upload,
    message,
    Space,
    Typography
} from 'antd';
import { BloodGroupData, DistrictsData } from '../Consts/DataConstants';
import { createNewData } from '../Actions/Main-Actions';

const NewCard = () => {


    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();
    const [districtCode, setDistrictCode] = useState("");
    const [loading, setLoading] = useState(false)

    const bloodGroupData = BloodGroupData;
    const options = DistrictsData;

    const handleDistrictSubSectionClick = (e, _label, option) => {
        e.stopPropagation();
        setDistrictCode(option.code);
    };
    const handleDistrictRender = (labels, selectedOptions) =>
        labels.map((label, i) => {
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return (
                    <span key={i} onChange={setDistrictCode(option.code)}>
                        {label} (<a onClick={(e) => handleDistrictSubSectionClick(e, label, option)}>{option.code}</a>)
                    </span>
                );
            }
            return <span key={i}>{label} - </span>;
        });

    const formulateData = () => {
        const data = {}
        const fd = form.getFieldValue();
        data['name'] = capitalizeFirstWord(fd.name);
        data['fname'] = capitalizeFirstWord(fd.fname);
        data['add1'] = capitalizeFirstWord(fd.add1);
        data['add2'] = capitalizeFirstWord(fd.add2);
        data['add3'] = capitalizeFirstWord(fd.add3);
        data['bg'] = fd.bg;
        data['district'] = fd.district;
        data['mno'] = fd.mno.trim();
        data['pin'] = fd.pin;
        data['dc'] = districtCode;
        data['dob'] = fd.dob.format("DD-MM-YYYY");
        data['pic'] = fileList[0].thumbUrl;
        data['timestamp'] = Date.now();
        data['status'] = "Active";
        data['alteredTimestamp'] = Date.now();
        return data;
    }

    const capitalizeFirstWord = (word) => {
        return word.trim().toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    const onReset = () => {
        setDistrictCode("");
        form.resetFields();
        setFileList([])

    }
    const onFinish = () => {
        setLoading(true)
        createNewData(formulateData()).then((_res) => {
            if (!_res || _res.error) {
                message.error(_res.error);
            } else {
                message.success('Card created Successfully !');
                onReset();
            }
            setLoading(false)
        }).catch(err => {
            console.error(err);
            message.error("Unable to Submit Data");
            setLoading(false)
        });
    };

    const onUploadPicChange = ({ file: newFile, fileList: newFileList }) => {
        setFileList(newFileList);
        // Overriding status to sucess
        newFile.status = "success"
    };
    const onUploadPicPreview = async (file) => {
        let src = file.url;
        if (file.size > 100000) {
            message.error("File Size Greater")
            return;
        }
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            }).then((src) => {
                return src;
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const onUploadFireAction = () => {
        return ''
    }


    const onBeforeUploadPic = () => {
        return true;
    }
    return (
        <>

            <Form
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 12,
                }}
                layout="horizontal"
                onFinish={onFinish}
                onReset={onReset}
            >

                <Form.Item label="Full Name (Surname FirstName)" name="name" rules={[
                    {
                        required: true,
                        type: 'string',
                        pattern: new RegExp(/^([A-Za-z ]){2,27}$/),
                        message: 'Invalid data - Max 27 characters & Allowed Upper/Lower Case and Space'
                    },
                ]}>
                    <Input placeholder='Surname FirstName' />
                </Form.Item>
                <Form.Item label="Fathers Name" name='fname' rules={[
                    {
                        required: true,
                        type: 'string',
                        pattern: new RegExp(/^([A-Za-z ]){2,33}$/),
                        message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space'
                    },
                ]}>
                    <Input placeholder="Father's Name" />
                </Form.Item>
                <Form.Item label="Blood Group" name='bg' rules={[
                    {
                        required: true,
                        message: 'Invalid data - Please select Blood Group'
                    },
                ]}>
                    <Select >
                        {bloodGroupData.map((e) => {
                            return <Select.Option key={e.value} value={e.value}>{e.value}</Select.Option>;
                        })}
                    </Select>

                </Form.Item>

                <Form.Item label="District" name='district' rules={[
                    {
                        required: true,
                        message: 'Invalid data - Please select District'
                    },
                ]}>
                    <Cascader options={options}

                        displayRender={handleDistrictRender}
                    />
                </Form.Item>
                <Form.Item label="District Code" >
                    <Typography>
                        {districtCode}
                    </Typography>
                </Form.Item>
                <Form.Item label="Date of Birth" name="dob" rules={[
                    {
                        required: true,
                        message: 'Invalid data - Please select Date of Birth'
                    },
                ]}>
                    <DatePicker placeholder='dd-mm-yyyy' format="DD-MM-YYYY" />
                </Form.Item>
                <Form.Item label="Address (Line 1)" name="add1" rules={[
                    {
                        required: true,
                        type: 'string',
                        pattern: new RegExp(/^([A-Za-z0-9-/,& ]){2,33}$/),
                        message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space (, / - &)'
                    },
                ]}>
                    <Input placeholder='Shop.No, Block Name' />
                </Form.Item>
                <Form.Item label="Address (Line 2)" name="add2" rules={[
                    {
                        required: true,
                        type: 'string',
                        pattern: new RegExp(/^([A-Za-z0-9-/,& ]){2,33}$/),
                        message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space (, / - &)'
                    },
                ]}>
                    <Input placeholder='Street Name' />
                </Form.Item>
                <Form.Item label="Address (Line 3)" name="add3" rules={[
                    {
                        type: 'string',
                        pattern: new RegExp(/^([A-Za-z0-9-/,& ]){2,33}$/),
                        message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space (, / - &)'
                    },
                ]}>
                    <Input placeholder='Land mark' />
                </Form.Item>
                <Form.Item label="Pin Code" name="pin" rules={[
                    {
                        required: true,
                        pattern: new RegExp(/^[5][1-4]\d{4}$/),
                        message: 'Invalid Pin Code No'
                    },
                ]}
                >
                    <InputNumber placeholder='510000' />
                </Form.Item>
                <Form.Item label="Mobile No" name="mno" rules={[
                    {
                        required: true,
                        pattern: new RegExp(/^[6789]\d{9}$/),
                        message: 'Invalid Mobile No'
                    },
                ]}>
                    <Input prefix="+91-" placeholder='9999999999' />
                </Form.Item>

                <Form.Item label="Upload" valuePropName="fileList" name="pic" rules={[
                    {
                        message: "Upload Pic for Card"
                    },
                ]}>
                    <ImgCrop rotate grid aspect={3 / 4}>
                        <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            action={onUploadFireAction}
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onUploadPicChange}
                            onPreview={onUploadPicPreview}
                            beforeUpload={onBeforeUploadPic}
                        >
                            {fileList.length < 1 && '+ Upload'}
                        </Upload>
                    </ImgCrop>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}>
                    <Space>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Create Card
                        </Button>
                        <Button htmlType="reset" disabled={loading}>
                            Reset
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
};
export default NewCard;













