import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import DistrictView from './DistrictView';
const MainView = () => {
    const membersStyle = ["state-honorable-members",'core-members','trust-board','voting-members','president-candidates','secretary-candidates','joint-secretary-candidates']
    const data = {
        "state-honorable-members": [
            {
                title: "",
                name: "యం. యస్. కోటేశ్వరరావు గారు",
                src: "assets/img/honorable-presidents/1.jpg",
            },
            {
                title: "",
                name: "రెడ్డి శ్రీనివాసరావు (నాని) గారు",
                src: "assets/img/honorable-presidents/2.jpg",
            },
            {
                title: "",
                name: "రవి కుమార్ గారు",
                src: "assets/img/honorable-presidents/3.jpg",
            }
        ],
        "core-members": [
            {
                title: "రాష్ట్ర ప్రెసిడెంట్",
                name: "మధుసూదన్ రెడ్డి గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-1.jpeg",
            },
            {
                title: "రాష్ట్ర సెక్రటరీ",
                name: "బొబ్బిలి బాలాజీ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-11.jpeg",
            },
            {
                title: "రాష్ట్ర వైస్ ప్రెసిడెంట్",
                name: "దుర్గాప్రసాద్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-29.jpeg",
            },
            {
                title: "రాష్ట్ర జాయింట్ సెక్రటరీ",
                name: "హరికిషోర్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-20.jpeg",
            },
            {
                title: "రాష్ట్ర ట్రెజరీ",
                name: "కె రామక్రిష్ణ ఆళ్ళగడ్డ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-22.jpeg",
            },
        ],
        "trust-board": [
            {
                title: "చైర్మన్",
                name: "R K రెడ్డి గారు",
                src: "assets/img/trust-board/1.jpg",
            },
            {
                title: "వైస్ చైర్మన్",
                name: "బసవరాజు గారు",
                src: "assets/img/trust-board/3.jpg",
            },
            {
                title: "వైస్ చైర్మన్",
                name: "గోపాలరావు గారు",
                src: "assets/img/trust-board/7.jpg",
            },
            {
                title: "సెక్రటరీ",
                name: "మిత్ర ప్రసాద్ గారు",
                src: "assets/img/trust-board/2.jpg",
            },
            {
                title: "జాయింట్ సెక్రటరీ",
                name: "శేషయ్య గారు",
                src: "assets/img/trust-board/6.jpg",
            },
            {
                title: "జాయింట్ సెక్రటరీ",
                name: "లక్ష్మి నారాయణ రెడ్డి గారు",
                src: "assets/img/trust-board/9.jpg",
            },
            {
                title: "ట్రెజరర్",
                name: "రవి కుమార్ గారు",
                src: "assets/img/trust-board/4.jpg",
            },
            {
                title: "అడిషనల్ ట్రెజరర్",
                name: "కన్నయ్య శెట్టి గారు",
                src: "assets/img/trust-board/5.jpg",
            },
            {
                title: "డైరెక్టర్",
                name: "లోకేష్ గారు",
                src: "assets/img/trust-board/8.jpg",
            },
        ],
        "ec-members": [
            {
                title: "కర్నూలు జిల్లా",
                name: "మధుసూదన్ రెడ్డి గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-1.jpeg",
            },
            {
                title: "నంద్యాల జిల్లా",
                name: "రామకృష్ణ గారు",
                src: "assets/img/ec-members/31.jpg",
            },
            
            {
                title: "శ్రీకాకుళం జిల్లా",
                name: "ఉమాశంకర్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-18.jpeg",
            },
            {
                title: "Y S R కడప జిల్లా",
                name: "హరి కిషోర్ గారు",
                src: "assets/img/ec-members/26.jpg",
            },
            {
                title: "పశ్చిమ గోదావరి జిల్లా",
                name: "శ్రీను గారు",
                src: "assets/img/ec-members/2.jpg",
            },
            {
                title: "గుంటూరు జిల్లా",
                name: "సుబ్బారావు గారు",
                src: "assets/img/ec-members/16.jpg",
            },
            {
                title: "తూర్పు గోదావరి జిల్లా",
                name: "B S V ప్రసాద్ గారు",
                src: "assets/img/ec-members/3.jpg",
            },

            {
                title: "కాకినాడ జిల్లా",
                name: "జి వి కె ప్రసాద్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-4.jpeg",
            },
            {
                title: "క్రిష్ణా జిల్లా",
                name: "తిరువూరు శేఖర్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-24.jpeg",
            },
            {
                title: "అనంతపురం జిల్లా",
                name: "ఆలీ గారు",
                src: "assets/img/ec-members/6.jpg",
            },
            {
                title: "ప్రకాశం జిల్లా",
                name: "బాషా గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-8.jpeg",
            },
            {
                title: "అనకాపల్లి జిల్లా",
                name: "త్రీనాధ్ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-9.jpeg",
            },
            {
                title: "విశాఖ జిల్లా",
                name: "మాధవ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-10.jpeg",
            },
            {
                title: "విజయనగరం జిల్లా",
                name: "బొబ్బిలి బాలాజీ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-11.jpeg",
            },
            {
                title: "మన్యం జిల్లా",
                name: "నాగభూషణం గారు",
                src: "assets/img/ec-members/12.jpg",
            },
            {
                title: "చిత్తూరు జిల్లా",
                name: "అశోక్ గారు",
                src: "assets/img/ec-members/13.jpg",
            },
            {
                title: "నెల్లూరు జిల్లా",
                name: "దుడ్డు రామక్రిష్ణ గారు",
                src: "assets/img/ec-members/IMG_605B691B08BC-19.jpeg",
            },
            {
                title: "తిరుపతి జిల్లా",
                name: "బాబు గారు",
                src: "assets/img/ec-members/30.jpg",
            },
            {
                title: "అన్నమయ్య జిల్లా",
                name: "పాజులూరు రెహమాన్ గారు",
                src: "assets/img/ec-members/32.jpg",
            },
            {
                title: "ఏలూరు జిల్లా",
                name: "వరప్రసాద్ గారు",
                src: "assets/img/ec-members/33.jpg",
            },
            {
                title: "బాపట్ల జిల్లా",
                name: "షేక్ మస్తాన్ గారు",
                src: "assets/img/ec-members/34.jpg",
            },
            {
                title: "అంబేద్కర్ కోనసీమ జిల్లా",
                name: "పట్టాభి రామారావుగారు",
                src: "assets/img/ec-members/35.jpg",
            },
        ],
        "district-members": [
            {
                title: "శ్రీకాకుళం జిల్లా",
                name: "డి రవి కుమార్ గారు",
                src: "assets/img/presidents/president-1.jpeg",
            },
            {
                title: "మన్యం జిల్లా",
                name: "అశోక్ గారు",
                src: "assets/img/presidents/president-14.jpg",
            },
            {
                title: "విశాఖపట్నం జిల్లా",
                name: "మూర్తి గారు",
                src: "assets/img/presidents/president-15.jpg",
            },
            {
                title: "అంబేద్కర్ కోనసీమ జిల్లా",
                name: "ప్రసాద్ గారు",
                src: "assets/img/presidents/president-16.jpg",
            },
            {
                title: "అనంతపురం జిల్లా",
                name: "దివాకర్ గారు",
                src: "assets/img/presidents/president-2.jpg",
            },
            {
                title: "అనకాపల్లి జిల్లా",
                name: "ఫరూక్ అలీ గారు",
                src: "assets/img/presidents/president-3.jpeg",
            },
            {
                title: "క్రిష్ణా జిల్లా",
                name: "పాషా గారు",
                src: "assets/img/presidents/president-4.jpg",
            },
            {
                title: "గుంటూరు జిల్లా",
                name: "నూరుద్దీన్ గారు",
                src: "assets/img/presidents/president-5.jpg",
            },
            {
                title: "పల్నాడు జిల్లా",
                name: "ఆంజనేయులు గారు",
                src: "assets/img/presidents/president-23.jpg",
            },
            {
                title: "తూర్పు గోదావరి జిల్లా",
                name: "సత్యనారాయణ గారు",
                src: "assets/img/presidents/president-6.jpeg",
            },

            {
                title: "ప్రకాశం జిల్లా",
                name: "నరసింహారాజు గారు",
                src: "assets/img/presidents/president-7.jpg",
            },
            {
                title: "కర్నూలు జిల్లా",
                name: "జనార్ధన గారు",
                src: "assets/img/presidents/president-8.jpg",
            },
            {
                title: "విజయనగరం జిల్లా",
                name: "రౌతు శ్రీనివాస్ గారు",
                src: "assets/img/presidents/president-9.jpeg",
            },
            {
                title: "నెల్లూరు జిల్లా",
                name: "గయాజ్ గారు",
                src: "assets/img/presidents/president-10.jpg",
            },
            {
                title: "అన్నమయ్య జిల్లా",
                name: "సయ్యద్ ఉస్మాన్ గారు",
                src: "assets/img/presidents/president-18.jpg",
            },
            {
                title: "కడప జిల్లా",
                name: "లక్ష్మి రెడ్డి గారు",
                src: "assets/img/presidents/president-11.jpeg",
            },
            {
                title: "నంద్యాల జిల్లా",
                name: "కన్నయ్య శెట్టి గారు",
                src: "assets/img/presidents/president-19.jpg",
            },
            {
                title: "చిత్తూరు జిల్లా",
                name: "రాజగోపాల్ గారు",
                src: "assets/img/presidents/president-17.jpg",
            },
            {
                title: "తిరుపతి జిల్లా",
                name: "లోకేష్ గారు",
                src: "assets/img/presidents/president-12.jpeg",
            },

            {
                title: "కాకినాడ జిల్లా",
                name: "నటరాజు గారు",
                src: "assets/img/presidents/president-13.jpeg",
            },
            {
                title: "పశ్చిమగోదావరి జిల్లా",
                name: "దుర్గాప్రసాద్ గారు",
                src: "assets/img/presidents/president-21.jpg",
            },
            {
                title: "బాపట్ల జిల్లా",
                name: "సుభాని గారు",
                src: "assets/img/presidents/president-20.jpg",
            },
            {
                title: "ఏలూరు జిల్లా",
                name: "దివాకర్ గారు",
                src: "assets/img/presidents/president-22.jpg",
            },
        ],
        gallery: [
            {
                src: 1,
                end: 0,
                title: "First Meet",
                location: "Vijayawada",
            },
            {
                src: 2,
                end: 14,
                title: "Second Meet",
                location: "Kurnool",
            },
            {
                src: 16,
                end: 17,
                title: "Third Meet",
                location: "Vijayawada",
            },
        ],
    };

    const getDistrictMembers = (data, name, viewType, colmd, rowval = 6) => {
        const updatedData = []
        for (let i = 0; i < data[name].length;) {
            const colData = []
            for (let x = 0; x < rowval && i < data[name].length; x++) {
                colData.push(data[name][i])
                i++;
            }
            updatedData.push(colData);
        }
        return <>
            {
                updatedData.map((val, i) => {
                    return (<div className="row">
                        {
                            val.map((col) => {
                               return membersStyle.includes(name) ? getVoterListSingle(col, viewType, colmd) : getDistrictSingle(col, viewType)
                            })
                        }
                    </div>
                    )
                })
            }
        </>

    }
    const getVoterListSingle = (data, viewType, colmd = 3) => {
        return <>
            <div className={"col-md-"+colmd}>
                <div className={viewType}>
                    <div className="pic">
                        <img src={data["src"]} alt="" /></div>
                    <h4>
                        {data["name"]}
                    </h4>
                    <span>
                        {data["title"]}
                    </span>
                </div>
            </div>
        </>
    }
    const getDistrictSingle = (data, viewType) => {
        return <>
            <div className="col-md-2">
                <div className={viewType}>
                    <div className="pic">
                        <img src={data["src"]} alt="" /></div>
                    <span style={{fontWeight:700, fontSize: "20px"}}>
                        {data["name"]}
                    </span>
                    <span>
                        {data["title"]}
                    </span>
                </div>
            </div>
        </>
    }
    useEffect(() => {
        init()
    }, [])
    const init = () => {

        /**
         * Easy selector helper function
         */
        const select = (el, all = false) => {
            el = el.trim();
            if (all) {
                return [...document.querySelectorAll(el)];
            } else {
                return document.querySelector(el);
            }
        };

        /**
         * Easy event listener function
         */
        const on = (type, el, listener, all = false) => {
            let selectEl = select(el, all);
            if (selectEl) {
                if (all) {
                    selectEl.forEach((e) => e.addEventListener(type, listener));
                } else {
                    selectEl.addEventListener(type, listener);
                }
            }
        };

        /**
         * Easy on scroll event listener
         */
        const onscroll = (el, listener) => {
            el.addEventListener("scroll", listener);
        };

        /**
         * Navbar links active state on scroll
         */
        let navbarlinks = select("#navbar .scrollto", true);
        const navbarlinksActive = () => {
            let position = window.scrollY + 300;
            navbarlinks.forEach((navbarlink) => {
                if (!navbarlink.hash) return;
                let section = select(navbarlink.hash);
                if (!section) return;
                if (
                    position >= section.offsetTop &&
                    position <= section.offsetTop + section.offsetHeight
                ) {
                    navbarlink.classList.add("active");
                } else {
                    navbarlink.classList.remove("active");
                }
            });
        };
        window.addEventListener("load", navbarlinksActive);
        onscroll(document, navbarlinksActive);

        /**
         * Scrolls to an element with header offset
         */
        const scrollto = (el) => {
            let header = select("#header");
            let offset = header.offsetHeight;

            let elementPos = select(el).offsetTop;
            window.scrollTo({
                top: elementPos - offset,
                behavior: "smooth",
            });
        };

        /**
         * Header fixed top on scroll
         */
        let selectHeader = select("#header");
        if (selectHeader) {
            let headerOffset = selectHeader.offsetTop;
            let nextElement = selectHeader.nextElementSibling;
            const headerFixed = () => {
                if (headerOffset - window.scrollY <= 0) {
                    selectHeader.classList.add("fixed-top");
                    nextElement.classList.add("scrolled-offset");
                } else {
                    selectHeader.classList.remove("fixed-top");
                    nextElement.classList.remove("scrolled-offset");
                }
            };
            window.addEventListener("load", headerFixed);
            onscroll(document, headerFixed);
        }

        /**
         * Back to top button
         */
        let backtotop = select(".back-to-top");
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add("active");
                } else {
                    backtotop.classList.remove("active");
                }
            };
            window.addEventListener("load", toggleBacktotop);
            onscroll(document, toggleBacktotop);
        }

        /**
         * Mobile nav toggle
         */
        on("click", ".mobile-nav-toggle", function (e) {
            select("#navbar").classList.toggle("navbar-mobile");
            this.classList.toggle("bi-list");
            this.classList.toggle("bi-x");
        });

        /**
         * Mobile nav dropdowns activate
         */
        on(
            "click",
            ".navbar .dropdown > a",
            function (e) {
                if (select("#navbar").classList.contains("navbar-mobile")) {
                    e.preventDefault();
                    this.nextElementSibling.classList.toggle("dropdown-active");
                }
            },
            true
        );

    }



    return (
        <>
            <section className="hero-bg">
                <div id="hero">
                    <div className="hero-container">
                        <div className="wow fadeIn">
                            <h1 className="main-logo">A E T U</h1>
                            <h2>We are <span style={{color:"#08c4eb"}}>Andhra Pradesh State Electronic TV Mechanics Union</span></h2>
                            <div className="actions">
                                <a href="#about" className="btn-get-started">About Us</a>
                                <Link className="nav-link" to="/login"><a href="#" className="btn-services">Login</a></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <header id="header" className="d-flex align-items-center">
                <div className="container d-flex align-items-center justify-content-between">

                    <h1 className="logo mr-auto"><a href="index.html">A E T U</a></h1>

                    <nav id="navbar" className="navbar">
                        <ul>
                            <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
                            <li><a className="nav-link scrollto" href="#about">About Us</a></li>
                            <li><a className="nav-link scrollto" href="#district-view">Members Metrics</a></li>
                            <li><a className="nav-link scrollto " href="#portfolio">Gallery</a></li>
                            <li className="dropdown"><a href="#"><span>Members</span><i className="bi bi-chevron-down toggle-dropdown"></i></a>
                                <ul>
                                    <li><a className="nav-link scrollto" href="#state-honorable-committee">State Honorable Presidents</a></li>
                                    <li><a className="nav-link scrollto" href="#state-committee">State Committee</a></li>
                                    <li><a className="nav-link scrollto" href="#trust-board-committee">Trust Board Committee</a></li>
                                    {/* <li class="dropdown"><a href="#"><span>Deep Dropdown</span> <i
                                                class="bi bi-chevron-down toggle-dropdown"></i></a>
                                        <ul>
                                            <li><a href="#">Deep Dropdown 1</a></li>
                                            <li><a href="#">Deep Dropdown 2</a></li>
                                            <li><a href="#">Deep Dropdown 3</a></li>
                                            <li><a href="#">Deep Dropdown 4</a></li>
                                            <li><a href="#">Deep Dropdown 5</a></li>
                                        </ul>
                                    </li> */}
                                    <li><a className="nav-link scrollto" href="#team">Executive Committee</a></li>
                                    <li><a className="nav-link scrollto" href="#district-presidents">District Presidents</a></li>
                                    <li><Link className="nav-link" to="/OldMembers">Old Committee</Link></li>
                                </ul>
                            </li>                            
                            {/* <li><a className="nav-link scrollto" href="#voting-members">Voting Members</a></li> */}
                            <li><a className="nav-link scrollto" href="#sankshema-fund">Sankshema Fund</a></li>
                            <li><Link className="nav-link" to="/login">Login</Link></li>
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>

                </div>
            </header>

            <main id="main">

                <section id="about">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">About Us</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="container about-container wow fadeInUp">
                        <div className="row">

                            <div className="col-lg-6 about-img">
                                <img src="assets/img/about-us-logo.png" alt="" />
                            </div>

                            <div className="col-md-6 about-content">
                                <h2 className="about-title">AETU ఆవిర్భావ చరిత్ర</h2>
                                <p className="about-text">
                                    ఆంధ్రప్రదేశ్ టీవీ టెక్నీషియన్స్ స్టేట్ యూనియన్ ఒక కల. కానీ చిన్న చిన్న యూనియన్లు కొన్నిచోట్ల జిల్లా యూనియన్ను ఏర్పడినవి.
                                    సి.ఆర్.టి యుగము నుండి ఎ.ల్.సిడి ఎల్.ఈ.డి టీవీ యుగము లోనికి మారినప్పుడు కొత్త టెక్నాలజీ అర్థం కాక ఇబ్బంది పడుతున్నప్పుడు
                                    దార్ల ఇండియా (దార్ల వెంకటేశ్వరరావు గారు) సెల్ ఫోన్ లో గ్రూపుల ద్వారా కొంతమంది టెక్నీషియన్లు మరియు ట్రైనర్లు కలుపుట వలన
                                    టెక్నీషియన్స్ మధ్య గ్రూపులో ఒక ఆత్మీయ కలయిక ఏర్పడుట జరిగినది.

                                </p>
                                <p className="about-text">
                                    ప్రత్యక్షముగా కలియ వలెనన్న సంకల్పముతో దుర్గా ప్రసాద్ గారు, ఆళ్లగడ్డ రామకృష్ణ గారు, బుల్లెట్ రాజు గారు మరియు కొన్ని
                                    జిల్లాల సహకారంతో 8-9-2019 విజయవాడ నందు ప్రత్యక్షముగా మొదటి ఆత్మీయ కలయిక జరిగి రాష్ట్ర యూనియన్ కు అంకురార్పణ జరిగింది అని
                                    చెప్పవచ్చు.

                                </p>
                                <p className="about-text">
                                    రెండవ ఆత్మీయ కలయిక కర్నూలు నందు ఏర్పాటు చేయుటకు ఆర్ కె రెడ్డి గారు మరియు కర్నూలు జిల్లా వారు ముందుకు వచ్చి 8-3-2020 కర్నూలు లో రెండవ ఆత్మీయ కలయిక
                                    సమావేశం ఏర్పాటు చేసి అప్పటివరకు రాష్ట్ర యూనియన్ ఏర్పడుతుందో లేదో అని ఉన్న అనుమానాలను పారద్రోలి JAC ఏర్పాటు చేయుట
                                    జరిగినది.
                                </p>
                                <p className="about-text">
                                    కరోనా ఉన్నప్పటికీ JAC పని చేస్తూనే ఉంది మరియు అన్ని జిల్లాల యూనియన్లను ఏర్పాటు చేసి 22-8-2021 విజయవాడ తుమ్మలపల్లి
                                    కళాక్షేత్రం నందు తమకు అప్పగించిన పనిని JAC దిగ్విజయంగా స్టేట్ యూనియన్ ఏర్పాటు చేయడం జరిగినది.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="district-view">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Registrered Members Metrics</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div style={{ overflow: 'scroll', display:'flex', justifyContent: "center" }}>
                            <DistrictView />
                        </div>
                    </div>
                </section>
                <section id="portfolio">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Gallery</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-center">
                                <ul id="portfolio-flters">
                                    <li data-filter="*" className="filter-active">All</li>
                                    {/* <li data-filter=".filter-1">1st Meet</li>
                                    <li data-filter=".filter-2">2nd Meet</li>
                                    <li data-filter=".filter-3">3rd Meet</li> */}
                                </ul>
                            </div>
                        </div>

                        <div className="row portfolio-container" id="portfolio-data">


                            {
                                Array.from({ length: 20 }, (x, i) => i + 1).map(i => {
                                    let srcVal = "assets/img/portfolio/" + i + ".jpeg";
                                    return <div key={i} className="col-lg-4 col-md-6 portfolio-item filter-1"><img src={srcVal} className="img-fluid" alt="" /></div>
                                })
                            }



                        </div>

                    </div>
                </section>
                <section id="state-honorable-committee">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">State Honorable Presidents</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="state-comity">
                            {
                                getDistrictMembers(data, "state-honorable-members", "profile", 4, 6)
                            }
                        </div>
                    </div>
                </section>
                <section id="state-committee">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">State Committee</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="state-comity">
                            {
                                getDistrictMembers(data, "core-members", "profile")
                            }
                        </div>
                    </div>
                </section>
                <section id="trust-board-committee">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Trust Board Committee</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="state-comity">
                            {
                                getDistrictMembers(data, "trust-board", "profile", 3, 9)
                            }
                        </div>
                    </div>
                </section>

                <section id="team">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Executive Committee</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="team-members">
                            {getDistrictMembers(data, "ec-members", "member")}
                        </div>
                    </div>

                </section>

                <section id="district-presidents">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">District Presidents</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="district-president-members">
                            {getDistrictMembers(data, "district-members", "member")}

                        </div>
                    </div>

                </section>

                <section id="sankshema-fund">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">సంక్షేమ నిధి</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="sankshema-fund-details">
                            <iframe title="Sankshema Fund" width="100%" height="800px" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTUHGq3U_Zn34ZIc9YAcSm5VY2AFOKPyiB3X4aBqjUNLrH7lWY6g8uSgDoaQfLBWA/pubhtml?widget=true&amp;headers=false"></iframe>
                        </div>
                    </div>
                    
                </section>
                
                {/* <section id="president-candidates">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">President Candidates</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="president-candidates">
                            {getDistrictMembers(voterlist, "president-candidates", "member")} 

                        </div>
                    </div>
                </section>

                <section id="secretary-candidates">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Secretary Candidates</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="secretary-candidates">
                            {getDistrictMembers(voterlist, "secretary-candidates", "member")} 

                        </div>
                    </div>
                </section>

                <section id="joint-secretary-candidates">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Joint Secretary Candidates</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="joint-secretary-candidates">
                            {getDistrictMembers(voterlist, "joint-secretary-candidates", "member")} 

                        </div>
                    </div>
                </section>

                <section id="voting-members">
                    <div className="container wow fadeInUp">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="section-title">Voting Members</h3>
                                <div className="section-title-divider"></div>
                            </div>
                        </div>
                        <div id="voting-members">
                            {getDistrictMembers(voterlist, "voting-members", "member")}

                        </div>
                    </div>
                </section>  */}

            </main>

            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright">
                                &copy; Copyright <strong>Imperial Theme</strong>. All Rights Reserved
                            </div>
                            <div className="credits">
                                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>. Integrated by Siva.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {/* <div id="preloader"></div> */}
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>

            {/* <script src="assets/vendor/aos/aos.js"></script>
            <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
            <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
            <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
            <script src="assets/vendor/php-email-form/validate.js"></script>
            <script src="assets/vendor/swiper/swiper-bundle.min.js"></script> */}

            <script src="assets/js/main.js"></script>
        </>
    )
}

export default MainView