import { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import {
    Form,
    Input,
    Button,
    Select,
    Switch,
    DatePicker,
    InputNumber,
    Upload,
    message,
    Typography,
    Modal
} from 'antd';
import { BloodGroupData } from '../Consts/DataConstants';
import { editRequest } from '../Actions/Main-Actions';


const EditView = (props) => {

    const { closeView, isOpen } = props;
    const closeModal = () => {
        onReset();
        closeView();
    }
    const [existingData, setExistingData] = useState();
    const [loading, setLoading] = useState(false);
    const [welfareScheme, setWelfareScheme] = useState(false);

    useEffect(() => {
        setLoading(false)
        form.resetFields()
        if (props.record) {
            const fileArr = []
            const fileObj = {
                thumbUrl: props.record['pic']
            }
            fileArr.push(fileObj)
            setExistingData(props.record);
            setWelfareScheme(props.record['isWelfareScheme'] === 'Yes')
            setFileList(fileArr)
        }
    }, [props])
    const [fileList, setFileList] = useState([]);
    const [form] = Form.useForm();

    const bloodGroupData = BloodGroupData;

    const formulateData = (values) => {
        const data = {}
        const fd = values
        data['name'] = capitalizeFirstWord(fd.name);
        data['fname'] = capitalizeFirstWord(fd.fname);
        data['add1'] = capitalizeFirstWord(fd.add1);
        data['add2'] = capitalizeFirstWord(fd.add2);
        data['add3'] = capitalizeFirstWord(fd.add3);
        data['isWelfareScheme'] = fd.welfarescheme ? 'Yes' : 'No';
        data['nominee'] = fd.welfarescheme ? capitalizeFirstWord(fd.nominee) : ''
        data['bg'] = fd.bg;
        data['mno'] = fd.mno.trim();
        data['pin'] = fd.pin;
        data['dob'] = fd.dob.format("DD-MM-YYYY");
        data['pic'] = fileList[0].thumbUrl;
        data['status'] = "Edit Requested";
        data['alteredTimestamp'] = Date.now();
        return data;
    }

    const diffObj = (obj1, obj2) => {
        const result = {};
        if (Object.is(obj1, obj2)) {
            return undefined;
        }
        if (!obj2 || typeof obj2 !== 'object') {
            return obj2;
        }
        Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
            if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                result[key] = obj2[key];
            }
            if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                const value = diffObj(obj1[key], obj2[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });
        Object.keys(result).forEach(key => result[key] === undefined && delete result[key])
        return result;
    }

    const capitalizeFirstWord = (word) => {
        return word.trim().toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    const onReset = () => {
        form.resetFields();
        setWelfareScheme(false);
        setFileList([])
        props = undefined
    }
    const onFinish = (values) => {
        const formulatedData = formulateData(values)
        const rData = { ...props.record }
        rData['dob'] = rData.dob.format("DD-MM-YYYY")
        const diffData = diffObj(rData, formulatedData)
        if (Object.keys(diffData).length === 2) {
            message.error("No changes made")
            setLoading(false)
            return
        }
        diffData['id'] = rData['id']
        diffData['uid'] = rData['uid']

        editRequest(diffData).then((res) => {
            if (!res || res.error) {
                message.error(res.error)
            } else {
                props.updateData(res)
                message.success("Edit Request Initiated !")
            }
            setLoading(false);
            closeView()
        }).catch((err) => {
            console.error(err);
            message.error(err)
            setLoading(false);
        })

        // createNewData(formulateData()).then((_res) => {
        //     message.success('Submit success!');
        //     onReset();
        // }).catch(err => {
        //     console.error(err);
        //     message.error("Unable to Submit Data");
        // });
    };

    const onUploadPicChange = ({ file: newFile, fileList: newFileList }) => {
        setFileList(newFileList);
        // Overriding status to sucess
        newFile.status = "success"
    };
    const onUploadPicPreview = async (file) => {
        let src = file.url;
        if (file.size > 100000) {
            message.error("File Size Greater")
            return;
        }
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            }).then((src) => {
                return src;
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const onUploadFireAction = () => {
        return ''
    }


    const onBeforeUploadPic = () => {
        return true;
    }
    return (
        <>
            <Modal title="Initiate Edit Request" open={isOpen} onCancel={closeModal} maskClosable={false}
                footer={[
                    <Button key="back" onClick={closeView} disabled={loading}>
                        Cancel
                    </Button>,
                    <Button key="submit" style={{ borderColor: "orange", color: "black", backgroundColor: "orange" }} loading={loading} onClick={() => {
                        setLoading(true);
                        form
                            .validateFields()
                            .then(async (values) => {
                                form.resetFields();
                                onFinish(values)
                            })
                            .catch((info) => {
                                console.error('Validate Failed:', info);
                                setLoading(false);
                            });
                    }}>
                        Request Edit
                    </Button>,
                ]}
            >
                {existingData &&
                    <Form
                        form={form}
                        initialValues={props.record}
                        labelCol={{
                            span: 12,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        layout="horizontal"
                    >
                        <Form.Item label="Card Id" >
                            <Typography>
                                {existingData.uid}
                            </Typography>
                        </Form.Item>
                        <Form.Item label="Full Name (Surname FirstName)" name="name" rules={[
                            {
                                required: true,
                                type: 'string',
                                pattern: new RegExp(/^([A-Za-z ]){2,27}$/),
                                message: 'Invalid data - Max 27 characters & Allowed Upper/Lower Case and Space'
                            },
                        ]}>
                            <Input placeholder='Surname FirstName' value={existingData.name} />
                        </Form.Item>
                        <Form.Item label="Fathers Name" name='fname' rules={[
                            {
                                required: true,
                                type: 'string',
                                pattern: new RegExp(/^([A-Za-z ]){2,33}$/),
                                message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space'
                            },
                        ]}>
                            <Input placeholder="Father's Name" />
                        </Form.Item>
                        <Form.Item label="Blood Group" name='bg' rules={[
                            {
                                required: true,
                                message: 'Invalid data - Please select Blood Group'
                            },
                        ]}>
                            <Select >
                                {bloodGroupData.map((e) => {
                                    return <Select.Option key={e.value} value={e.value}>{e.value}</Select.Option>;
                                })}
                            </Select>

                        </Form.Item>

                        <Form.Item label="District Code" >
                            <Typography>
                                {existingData.district}
                            </Typography>
                        </Form.Item>
                        <Form.Item label="Date of Birth" name="dob" rules={[
                            {
                                required: true,
                                message: 'Invalid data - Please select Date of Birth'
                            },
                        ]}>
                            <DatePicker placeholder='dd-mm-yyyy' format="DD-MM-YYYY" />
                        </Form.Item>
                        <Form.Item label="Address (Line 1)" name="add1" rules={[
                            {
                                required: true,
                                type: 'string',
                                pattern: new RegExp(/^([A-Za-z0-9-/,& ]){2,33}$/),
                                message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space (, / - &)'
                            },
                        ]}>
                            <Input placeholder='Shop.No, Block Name' />
                        </Form.Item>
                        <Form.Item label="Address (Line 2)" name="add2" rules={[
                            {
                                required: true,
                                type: 'string',
                                pattern: new RegExp(/^([A-Za-z0-9-/,& ]){2,33}$/),
                                message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space (, / - &)'
                            },
                        ]}>
                            <Input placeholder='Street Name' />
                        </Form.Item>
                        <Form.Item label="Address (Line 3)" name="add3" rules={[
                            {
                                type: 'string',
                                pattern: new RegExp(/^([A-Za-z0-9-/,& ]){2,33}$/),
                                message: 'Invalid data - Max 33 characters & Allowed Upper/Lower Case and Space (, / - &)'
                            },
                        ]}>
                            <Input placeholder='Land mark' />
                        </Form.Item>
                        <Form.Item label="Pin Code" name="pin" rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^[5][1-4]\d{4}$/),
                                message: 'Invalid Pin Code No'
                            },
                        ]}
                        >
                            <InputNumber placeholder='510000' />
                        </Form.Item>
                        <Form.Item label="Mobile No" name="mno" rules={[
                            {
                                required: true,
                                pattern: new RegExp(/^[6789]\d{9}$/),
                                message: 'Invalid Mobile No'
                            },
                        ]}>
                            <Input prefix="+91-" placeholder='9999999999' />
                        </Form.Item>
                        <Form.Item label="Member of Family Welfare Scheme" name="welfarescheme">
                            <Switch checked={welfareScheme} checkedChildren="Yes" unCheckedChildren="No" onChange={() => setWelfareScheme(!welfareScheme)}/>
                        </Form.Item>
                        {
                            welfareScheme &&
                            <Form.Item label="Nominee Name" name="nominee" rules={[
                                {
                                    required: welfareScheme,
                                    type: 'string',
                                    pattern: new RegExp(/^([A-Za-z ]){2,27}$/),
                                    message: 'Invalid data - Max 27 characters & Allowed Upper/Lower Case and Space'
                                },
                            ]}>

                                <Input placeholder='Surname FirstName' value={existingData.nominee} />
                            </Form.Item>
                        }
                        <Form.Item label="Upload" valuePropName="fileList" name="pic" rules={[
                            {
                                // required: true,
                                message: "Upload Pic for Card"
                            },
                        ]}>
                            <ImgCrop rotate grid aspect={3 / 4}>
                                <Upload
                                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    action={onUploadFireAction}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onUploadPicChange}
                                    onPreview={onUploadPicPreview}
                                    beforeUpload={onBeforeUploadPic}
                                >
                                    {fileList.length < 1 && '+ Upload'}
                                </Upload>
                            </ImgCrop>
                        </Form.Item>
                        {/* <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button htmlType="reset">
                                    Reset
                                </Button>
                            </Space>
                        </Form.Item> */}
                    </Form>
                }
            </Modal>
        </>
    );
};
export default EditView;

