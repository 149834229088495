import { SearchOutlined, EyeOutlined, ScheduleOutlined, EditOutlined, DeleteFilled, DownloadOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Table, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { FullCard } from '../Card/FullCard';
import { processSheetData } from '../Utils/Utils';
import DeleteView from './DeleteView'
import './TableView.css';
import EditView from './EditView';
import { fetchImageByUid } from '../Actions/Main-Actions';
import moment from 'moment';
import { FullWelfareCard } from '../Card-Welfare/FullWelfareCard';
const saveSvgAsPng = require('save-svg-as-png')

const imageOptions = {
    scale: 10,
    encoderOptions: 1,
    backgroundColor: 'white',
    top: -25,
    left: -25,
    width: 300,
    height: 450
}

const welfareImageOptions = {
    scale: 5,
    encoderOptions: 1,
    backgroundColor: 'white',
    top: -15,
    left: -15,
    // width: 2480,
    // height: 3508
    width: 827,
    height: 1169
}

const TableView = (props) => {
    const [data, setData] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [viewDialog, setViewDialog] = useState(false);
    const [welfareSchemeDialog, setWelfareSchemeDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [editViewDialog, setEditViewDialog] = useState(false);
    const [record, setRecord] = useState();
    const [editRecord, setEditRecord] = useState();
    const [deleteViewDialog, setDeleteViewDialog] = useState(false);

    useEffect(() => {
        setData(processSheetData(props.data))
    }, [props])

    const updateActionData = (newData) => {
        data.forEach(v => {
            if (v.id === newData.id) {
                v['status'] = newData['status']
            }
        })
        setData(data)
    }
    const showDeleteViewDialog = (record) => {
        setDeleteViewDialog(true)
        setRecord(record)
    }

    const closeDeleteViewDialog = () => {
        setDeleteViewDialog(false)
    }

    const showEditViewDialog = (record) => {
        setEditRecord(record)
        setEditViewDialog(true)
    }

    const closeEditViewDialog = () => {
        setEditViewDialog(false)
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const downloadFile = (uid, type) => {
        const ele = type === "scheme" ? 'fullwelfarecard' : 'fullcard'
        const imgOption = type === "scheme" ? welfareImageOptions : imageOptions
        saveSvgAsPng.saveSvgAsPng(document.getElementById(ele), uid + ".png", imgOption);
    }

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, dataPlaceHolder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataPlaceHolder}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Card ID',
            dataIndex: 'uid',
            key: 'uid',
            width: '20%',
            ...getColumnSearchProps('uid', 'Card ID'),
            sorter: (a, b) => a.uid === undefined ? 1 : a.uid.localeCompare(b.uid),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            ...getColumnSearchProps('name', 'Name'),
            sorter: (a, b) => a.name === undefined ? 1 : a.name.localeCompare(b.name),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Father Name',
            dataIndex: 'fname',
            key: 'fname',
            ...getColumnSearchProps('fname', 'Father Name'),
            sorter: (a, b) => a.fname === undefined ? 1 : a.fname.localeCompare(b.fname),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Mobile No',
            dataIndex: 'mno',
            key: 'mno',
            ...getColumnSearchProps('mno', 'Mobile No'),
            sorter: (a, b) => a.mno === undefined ? 1 : a.mno.localeCompare(b.mno),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Welfare Scheme',
            dataIndex: 'isWelfareScheme',
            key: 'isWelfareScheme',
            ...getColumnSearchProps('isWelfareScheme', 'Welfare Scheme'),
            sorter: (a, b) => a.isWelfareScheme === undefined ? -1 : a.isWelfareScheme.localeCompare(b.isWelfareScheme),
            render: (_, tag) => (
                <Tag color={(() => {
                    if (tag['isWelfareScheme'] === 'Yes') {
                        return 'green'
                    } else {
                        return 'red'
                    }
                })()}>
                    {tag['isWelfareScheme'] ? tag['isWelfareScheme'] : 'No'}
                </Tag>
            ),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status', 'Status'),
            sorter: (a, b) => a.status === undefined ? 1 : a.status.localeCompare(b.status),
            sortDirections: ['descend', 'ascend'],
            render: (_, tag) => (
                <Tag color={(() => {
                    if (tag['status'] === 'Active') {
                        return 'green'
                    } else if (tag['status'] === 'Edit Requested') {
                        return 'orange'
                    } else {
                        return 'red'
                    }
                })()}>
                    {tag['status'].toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_uid, recData, _index) =>
                <Space>
                    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {(recData['status'] === 'Active') && (recData['uid']) &&
                            <>
                                <Button type="primary" style={{marginRight:"5px"}} onClick={() => {
                                    setViewDialog(true);
                                    setSelectedRow(recData);
                                }}>
                                    View <EyeOutlined />
                                </Button>
                                {
                                recData['isWelfareScheme'] === 'Yes' &&
                                <Button style={{ color: "#205918", borderColor: "#205918", marginRight:"5px" }}  onClick={() => {
                                    setWelfareSchemeDialog(true);
                                    setSelectedRow(recData);
                                }}>
                                    Welfare Scheme <ScheduleOutlined />
                                </Button>
                                }
                                {
                                ((Date.now() - recData['alteredTimestamp']) > 86400000 || recData['timestamp'] === recData['alteredTimestamp']) 
                                &&
                                    <>
                                        <Button style={{ color: "#bf8e00", borderColor: "#bf8e00", marginRight:"5px" }} onClick={() => {
                                            fetchImageByUid(recData.id).then((res) => {
                                                const rec = { ...recData }
                                                rec['pic'] = res;
                                                rec['dob'] = moment(rec['dob'], 'DD-MM-YYYY')
                                                showEditViewDialog(rec)
                                            })
                                        }}>
                                            Edit <EditOutlined />
                                        </Button>
                                        <Button danger style={{marginRight:"5px"}} onClick={() => {
                                            showDeleteViewDialog(recData)
                                        }}>
                                            Delete <DeleteFilled />
                                        </Button>
                                    </>
                                }
                            </>
                        }
                    </div>
                </Space>
            ,
        }
    ];
    return <>
        <Table columns={columns} dataSource={data} className="table-main" />
        {
            selectedRow
            &&
            viewDialog 
            && 
            <Modal
                title={`${selectedRow.name}`}
                centered
                open={viewDialog}
                onCancel={() => setViewDialog(false)}
                footer={[
                    <Button key="back" onClick={() => setViewDialog(false)}>
                        <MinusCircleOutlined /> Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => downloadFile(selectedRow.uid, 'card')}>
                        <DownloadOutlined /> Download
                    </Button>,
                ]}
            >
                <FullCard data={selectedRow} />
            </Modal>
        }
        {
            selectedRow
            && 
            welfareSchemeDialog
            &&
            <Modal
                title={`${selectedRow.name}`}
                centered
                open={welfareSchemeDialog}
                onCancel={() => setWelfareSchemeDialog(false)}
                width={870}
                footer={[
                    <Button key="back" onClick={() => setWelfareSchemeDialog(false)}>
                        <MinusCircleOutlined /> Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => downloadFile(selectedRow.uid, "scheme")}>
                        <DownloadOutlined /> Download
                    </Button>,
                ]}
            >
                <FullWelfareCard data={selectedRow} />
            </Modal>
        }
        <EditView isOpen={editViewDialog} closeView={closeEditViewDialog} record={editRecord} updateData={updateActionData} ></EditView>
        <DeleteView isOpen={deleteViewDialog} closeView={closeDeleteViewDialog} record={record} updateData={updateActionData}></DeleteView>
    </>;
};

export default TableView;