import { Button, Space, Table, message } from 'antd';
import { loadAllEditRequests, loadAllDeleteRequests, approveDeleteRequest, revokeDeleteRequest, revokeEditRequest, approveEditRequest } from '../Actions/Main-Actions';
import { useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { isValidAdditional } from '../Utils/Utils';

const MergeRequestsView = () => {

    const [editRequests, setEditRequests] = useState([]);
    const [deleteRequests, setDeleteRequests] = useState([]);

    const editColumns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Card Id',
            dataIndex: 'uid',
            key: 'uid',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Father Name',
            dataIndex: 'fname',
            key: 'fname',
        },
        {
            title: 'Blood Group',
            dataIndex: 'bg',
            key: 'bg',
        },
        {
            title: 'Address 1',
            dataIndex: 'add1',
            key: 'add1',
        },
        {
            title: 'Address 2',
            dataIndex: 'add2',
            key: 'add2',
        },
        {
            title: 'Address 3',
            dataIndex: 'add3',
            key: 'add3',
        },
        {
            title: 'Mobile No',
            dataIndex: 'mno',
            key: 'mno',
        },
        {
            title: 'Pin code',
            dataIndex: 'pin',
            key: 'pin',
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
        },
        {
            title: 'Welfare Scheme',
            dataIndex: 'isWelfareScheme',
            key: 'isWelfareScheme'
        },
        {
            title: 'Nominee',
            dataIndex: 'nominee',
            key: 'nominee'
        },
        {
            title: 'Pic',
            dataIndex: 'pic',
            key: 'pic',
            render: theImageURL => <img alt={theImageURL} src={theImageURL} />
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_uid, record, _index) =>
                <Space>
                    <Button type="primary" onClick={() => {
                        editRequestApprove(record)
                    }}>
                        Approve <CheckOutlined />
                    </Button>
                    <Button danger onClick={() => {
                        editRequestRevoke(record)
                    }}>
                        Revoke <CloseOutlined />
                    </Button>
                </Space>
        }
    ];



    const deleteColumns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Card Id',
            dataIndex: 'uid',
            key: 'uid',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'action',
            render: (_uid, record, _index) =>
                <Space>
                    <Button type="primary" onClick={() => {
                        deleteRequestApprove(record)
                    }}>
                        Approve <CheckOutlined />
                    </Button>
                    <Button danger onClick={() => {
                        deleteRequestRevoke(record)
                    }}>
                        Revoke <CloseOutlined />
                    </Button>
                </Space>
        }
    ];
    const loadEditRequests = async () => {
        const data = await loadAllEditRequests();
        setEditRequests(data);
    }
    const loadDeleteRequests = async () => {
        const data = await loadAllDeleteRequests();
        setDeleteRequests(data);
    }
    const deleteRequestApprove = async (record) => {
        const data = await approveDeleteRequest(record)
        if (!data || data['error']) {
            message.error("Unable to Approve the request")
            return;
        }
        const allDeleteRequests = deleteRequests;
        const newData = []
        allDeleteRequests.forEach(val => {
            if (record['id'] !== val['id']) {
                newData.push(val)
            }
        })
        setDeleteRequests(newData)
        message.info("Updated the request")
    }

    const deleteRequestRevoke = async (record) => {
        const data = await revokeDeleteRequest(record)
        if (!data || data['error']) {
            message.error("Unable to Revoke the request")
            return;
        }
        const allDeleteRequests = deleteRequests;
        const newData = []
        allDeleteRequests.forEach(val => {
            if (record['id'] !== val['id']) {
                newData.push(val)
            }
        })
        setDeleteRequests(newData)
        message.info("Revoked the request")
    }

    const editRequestApprove = async (record) => {
        const data = await approveEditRequest(record)
        if (!data || data['error']) {
            message.error("Unable to Approve the request")
            return;
        }
        const allEditRequests = editRequests;
        const newData = []
        allEditRequests.forEach(val => {
            if (record['id'] !== val['id']) {
                newData.push(val)
            }
        })
        setEditRequests(newData)
        message.info("Updated the request")
    }

    const editRequestRevoke = async (record) => {
        const data = await revokeEditRequest(record)
        if (!data || data['error']) {
            message.error("Unable to Revoke the request")
            return;
        }
        const allEditRequests = editRequests;
        const newData = []
        allEditRequests.forEach(val => {
            if (record['id'] !== val['id']) {
                newData.push(val)
            }
        })
        setEditRequests(newData)
        message.info("Revoked the request")
    }

    return (
        isValidAdditional() &&
        <>
            <Space>
                <Button onClick={loadEditRequests}>Edit Requests</Button>
            </Space>
            <Table dataSource={editRequests} columns={editColumns} className="table-main" />
            <Space style={{ marginTop: '40px' }}>
                <Button onClick={loadDeleteRequests}>Delete Requests</Button>
            </Space>
            <Table dataSource={deleteRequests} columns={deleteColumns} className="table-main" />
        </>
    )
}

export default MergeRequestsView