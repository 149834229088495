import React, { useState } from 'react';
import 'antd/dist/antd.min.css'

import CardView from './Views/CardView';
import NewCard from './Views/NewCard';


import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import LayoutView from './Views/LayoutView';
import LoginView from './Views/LoginView';
import MergeRequestsView from './Views/MergeRequestsView';
import MainView from './Views/MainView';
import MaintenanceView from './Views/MaintenanceView';
import { CookiesProvider } from "react-cookie";
import OldMembers from './Views/OldMembers';

const App = () => {

  return (

    <CookiesProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<MainView />} />
          <Route exact path="/login" element={<LoginView />} />
          <Route exact path="/OldMembers" element={<OldMembers />} />
          <Route path="/in" element={<LayoutView />}>
            <Route path="new" element={<NewCard />} />
            <Route path="view" element={<CardView />} />
            <Route path="req" element={<MergeRequestsView />} />
          </Route>
          <Route exact path="/maintenance" element={<MaintenanceView />} />
          {/* <Route path="*" element={<NotFound/>}/> */}
        </Routes>
      </Router>
    </CookiesProvider>
  );
};

export default App;